import React, { useState } from 'react';

import { Button, DatePicker, Form, Input } from 'antd';

import moment from 'moment';
import { Discount, useUpsertDiscountMutation } from '../Generated/graphql';

export interface Props {
  productId: string;
  discount: Pick<Discount, 'id' | 'activeFrom' | 'activeTo' | 'discountPercentage'> | undefined;
}

const ProductItemDiscount: React.FC<Props> = (props) => {
  const [form] = Form.useForm();

  const [upsertDiscountMutation, { data, loading, error }] = useUpsertDiscountMutation();

  const onFinish = async (values) => {
    console.log('Received values of form:', values);

    upsertDiscountMutation({
      variables: {
        discount: {
          id: props.discount?.id,
          name: 'Test',
          activeFrom: values.active[0].toISOString(),
          activeTo: values.active[1]?.toISOString(),
          criteria: {
            and: [],
            or: [],
            productId: props.productId,
          },
          discountPercentage: values.discountPercentage,
        },
      },
    });
  };

  return (
    <div style={{ marginBottom: 4 }}>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="inline"
        initialValues={{
          active: props.discount
            ? [moment(props.discount.activeFrom), props.discount.activeTo ? moment(props.discount.activeTo) : null]
            : undefined,
          discountPercentage: props.discount?.discountPercentage,
        }}
      >
        <Form.Item name="active" label="" rules={[{ required: true, message: 'Active period is missing' }]}>
          <DatePicker.RangePicker
            allowEmpty={[false, true]}
            // disabledDate={disabledDate}
            // disabledTime={disabledRangeTime}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
            }}
          />
        </Form.Item>
        <Form.Item
          label=" "
          name="discountPercentage"
          tooltip="Discount in %"
          rules={[{ required: true, message: 'Discount % is missing' }]}
        >
          <Input type="number" placeholder="Discount in %" defaultValue={props.discount?.discountPercentage} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProductItemDiscount;
