import { Breadcrumb, Button, Col, Input, PageHeader, Pagination, Popover, Row } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlaceParticipantContext } from '../contexts/placeParticipantContext';
import { useProductListLazyQuery } from '../Generated/graphql';
import debounce from 'lodash/debounce';
import { PictureOutlined } from '@ant-design/icons';

const ProductListScreen: React.FC = () => {
  const history = useHistory();

  const { placeParticipantId } = useContext(PlaceParticipantContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [query, setQuery] = useState('');

  if (!placeParticipantId) return <div>Error</div>;

  const [search, { loading, data }] = useProductListLazyQuery();

  useEffect(() => {
    search({
      variables: {
        query: query,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      },
    });
  }, [query, currentPage, pageSize]);

  function itemRender(
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
  ) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setQuery(nextValue), 500),
    [],
  );

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Products</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => history.push('/products')}
        title="Products"
        // subTitle="This is a subtitle"
        extra={[
          <Button key="1" type="primary">
            Add product
          </Button>,
        ]}
        style={{ paddingBottom: 15, borderBottom: '1px solid #d9d9d9' }}
      ></PageHeader>
      <div
        style={{
          backgroundColor: 'white',
          marginTop: 5,
          marginBottom: 5,
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div></div>
        <div>
          <Input placeholder={'Search'} onChange={(q) => debouncedSearch(q.target.value)} />
        </div>
      </div>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
        {loading ? (
          <div>...loading</div>
        ) : (
          <>
            <div style={{ marginBottom: 20 }}>
              {data?.productList.products.map((x, i) => (
                <Row
                  key={x.id}
                  wrap={false}
                  style={{ backgroundColor: i % 2 ? 'rgb(241 238 238)' : 'transparent' }}
                  gutter={8}
                >
                  <Col xs={1}>{x.productId}</Col>
                  <Col flex="auto">
                    <Link to={'/store/products/' + x.id}>{(x.name ?? '').trim().length !== 0 ? x.name : '-'}</Link>
                  </Col>
                  <Col xs={6}>{x.category?.name ?? 'No-category'}</Col>
                  <Col xs={1}>{x.summary.in - x.summary.out}</Col>
                  <Col xs={1}>
                    {x.summary.in} - {x.summary.out}
                  </Col>
                  <Col>
                    {x.primaryPicture ? (
                      <Popover
                        content={
                          <div>
                            <img
                              width={400}
                              height={400}
                              src={'https://files.orderindo.com/images' + x.primaryPicture?.url}
                            ></img>
                          </div>
                        }
                        placement="topRight"
                      >
                        <PictureOutlined />
                      </Popover>
                    ) : (
                      <div style={{ width: '1em' }}></div>
                    )}
                  </Col>
                </Row>
              ))}
            </div>
            <Pagination
              current={currentPage}
              total={data?.productList.totalItemCount}
              pageSize={pageSize}
              itemRender={itemRender}
              onChange={(x) => {
                setCurrentPage(x);
              }}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductListScreen;
